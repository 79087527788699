<template>
  <head-top :fnItem="fn">
    <div class="pay">
      <div class="pay-top">
        <div class="pay-amount">￥{{ zhuanfen(amountTotal) }}</div>
        <div class="pay-date">
          <span class="span1">支付剩余时间：</span>
          <span class="span2">{{ hou ? hou : "00" }}</span>
          <span class="span3">{{ min ? min : "00" }}</span>
          <span class="span3">{{ sec ? sec : "00" }}</span>
        </div>
      </div>
      <div class="pay-way">
        <span class="pay-content">支付方式</span>
      </div>
      <div class="wx-pay">
        <div class="left">
          <img src="../../assets/wx.png" alt="" />
          微信支付
        </div>
        <div class="right">
          <van-radio-group v-model="radio">
            <van-radio name="1"></van-radio>
          </van-radio-group>
        </div>
      </div>
      <!-- 确定支付 -->
      <div class="determine-pay" @click="payAmount">
        确认支付：￥{{ zhuanfen(amountTotal) }}
      </div>
    </div>
  </head-top>
</template>

<script>
import headTop from "../../components/head.vue";
import { unifiedOrder } from "../../api/home/index.js";
import { Dialog } from "vant";
export default {
  components: {
    headTop,
  },
  data() {
    return {
      radio: "1",
      hou: 0,
      min: 0,
      sec: 0,
      endTime: "",
      disprArr: [],
      _interval: "",
      amountTotal: "",
    };
  },
  //当离开页面时，清除倒计时
  beforeDestroy() {
    clearInterval(this._interval);
  },
  created() {
    this.amountTotal = this.$route.query.amountTotal;
    this.countdown(
      this.$route.query.remainSec ? this.$route.query.remainSec : 0
    );
  },
  methods: {
    //倒计时事件
    countdown(countTime) {
      const that = this;
      that._interval = setInterval(() => {
        if (countTime == 0) {
          // 计时结束，清除缓存
          clearInterval(that._interval);
        } else {
          countTime--;
          let min = parseInt((countTime / 60) % 60);
          let sec = parseInt(countTime % 60);
          min = min > 9 ? min : "0" + min;
          sec = sec > 9 ? sec : "0" + sec;
          that.min = min;
          that.sec = sec;
        }
      }, 1000);
    },
    zhuanfen(roomPrice) {
      return (roomPrice / 100).toFixed(2);
    },
    // 确认支付
    async payAmount() {
      let _this = this;
      let data = {
        orderId: this.$route.query.orderId,
      };
      let res = await unifiedOrder(data);
      if (res.code == 200) {
        let list = res.data;
        WeixinJSBridge.invoke(
          "getBrandWCPayRequest",
          {
            appId: list.appId, // 必填，公众号的唯一标识
            timeStamp: list.timeStamp, // 必填，生成签名的时间戳
            nonceStr: list.nonceStr, // 必填，生成签名的随机串
            package: list.package, //统一下单接口返回的prepay_id参数值，提交格式如：prepay_id=***
            signType: list.signType, // 签名类型，默认为MD5，支持HMAC-SHA256和MD5。注意此处需与统一下单的签名类型一致
            paySign: list.paySign, //签名，详见签名生成算法
          },
          (wxRes) => {
            if (wxRes.err_msg == "get_brand_wcpay_request:ok") {
              this.$toast.success("支付成功");
              //成功
              setTimeout(() => {
                _this.$router.push({
                  name: "paySuccessful",
                  query: {
                    orderId: this.$route.query.orderId,
                  },
                });
              }, 1000);
            } else if (wxRes.err_msg == "get_brand_wcpay_request:cancel") {
              //取消
              this.$toast("取消支付");
            } else if (wxRes.err_msg == "get_brand_wcpay_request:fail") {
              //失败
              this.$toast("支付失败");
            }
          }
        );
      }
    },
    fn() {
      Dialog.confirm({
        title: "",
        message: "确定退出支付吗？",
      })
        .then(() => {
          // on confirm
          this.$router.push({
            name: "payList",
          });
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style lang="scss">
.pay {
  position: relative;
  .pay-top {
    height: 600px;
    background-color: #fff;
    .pay-amount {
      padding-top: 150px;
      font-size: 70px;
      font-weight: 600;
      color: #ea5235;
      text-align: center;
    }
    .pay-date {
      text-align: center;
      margin-top: 20px;
      .span1 {
        font-size: 30px;
      }
      .span2,
      .span3,
      .span4 {
        display: inline-block;
        width: 40px;
        height: 30px;
        font-size: 28px;
        text-align: center;
        line-height: 30px;
        border: 1px solid #ccc;
        margin-right: 10px;
      }
    }
  }
  .pay-way {
    text-align: center;
    background-color: #fff;

    .pay-content {
      font-size: 30px;
      color: #ccc;
    }
  }
  .wx-pay {
    height: 100px;
    line-height: 100px;
    display: flex;
    padding-top: 30px;
    background-color: #fff;
    div {
      flex: 1;
    }
    .left {
      padding-left: 20px;
      font-size: 26px;
      img {
        width: 60px;
        height: 60px;
        vertical-align: middle;
      }
    }
    .right {
      text-align: right;
      line-height: 100px;
    }
  }
  .determine-pay {
    height: 100px;
    width: 100%;
    background-color: #ea5235;
    color: #fff;
    line-height: 100px;
    font-size: 32px;
    position: fixed;
    bottom: 0;
    text-align: center;
  }
}
</style>
<style lang="scss">
.pay {
  .van-radio-group,
  .van-radio {
    height: 100% !important;
    padding-right: 20px;
  }
  .van-icon {
    float: right;
    background-color: #ea5235;
    border-color: #ea5235;
  }
}
</style>